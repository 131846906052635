import { Space } from "antd";
import { motion } from "framer-motion";
import Card from "../../components/Card";
import {
  CASINO_IMAGE_ENTERANCE_DELAY,
  CASINO_IMAGE_ENTERANCE_DURATION,
  DELAY_PER_CONTENT_CARD,
} from "../../constants/animation";
import { contents } from "../../constants/content";

const Home = () => {
  return (
    <Space className="app-content" wrap>
      <Space
        className="main-contents-container"
        style={{ width: "100%", justifyContent: "center" }}
        align="start"
        wrap
      >
        {contents.map((content, index) => (
          <Card
            key={index}
            title={content.title}
            contents={content.contents}
            button={{
              title: content.action.title,
              link: content.action.link,
            }}
            delay={index * DELAY_PER_CONTENT_CARD}
          />
        ))}
      </Space>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0, animationDelay: "20" }}
        transition={{
          ease: "easeOut",
          duration: CASINO_IMAGE_ENTERANCE_DURATION,
          type: "spring",
          delay: CASINO_IMAGE_ENTERANCE_DELAY,
        }}
      >
        <Space
          className="casino-container"
          style={{ width: "100%", justifyContent: "center" }}
        >
          <img src="/assets/casino-1.png" alt="casino-1" />
        </Space>
      </motion.div>
    </Space>
  );
};

export default Home;
