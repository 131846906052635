import { Card as AntdCard, Button } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { CARD_ENTERANCE_DURATION } from "../../constants/animation";

interface CardProps {
    title: string;
    contents: string[];
    button: {
        title: string;
        link: string;
    };
    delay?: number;
}

const Card = ({ title, contents, button, delay }: CardProps) => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0, animationDelay: "20" }}
                transition={{
                    ease: "easeOut",
                    duration: CARD_ENTERANCE_DURATION,
                    type: "spring",
                    delay,
                }}
            >
                <AntdCard
                    className="content-card"
                    title={title}
                    bordered={false}
                    style={{
                        width: 400,
                        backgroundColor: "transparent",
                        textAlign: "left",
                        boxShadow: "none",
                        boxSizing: "border-box",
                    }}
                    headStyle={{
                        border: "none",
                        color: "#9AD7FF",
                        fontSize: 32,
                        whiteSpace: "pre-wrap",
                    }}
                    bodyStyle={{
                        color: "#FFF",
                        fontSize: 24,
                        padding: 5,
                    }}
                >
                    <motion.ul style={{ height: 190 }}>
                        {contents.map((content, index) => (
                            <motion.li
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                }}
                                transition={{
                                    ease: "easeOut",
                                    duration: CARD_ENTERANCE_DURATION,
                                    type: "spring",
                                    delay:
                                        (delay || 0) +
                                        // CARD_ENTERANCE_DURATION / 2 +
                                        index * 0.1,
                                }}
                            >
                                {content}
                            </motion.li>
                        ))}
                    </motion.ul>
                    <a
                        href={process.env[button.link]}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            style={{
                                width: "100%",
                                height: 55,
                                color: "#9AD7FF",
                                background: "#353880",
                                fontSize: 20,
                                borderRadius: 12,
                                border: "none",
                            }}
                        >
                            {button.title}
                        </Button>
                    </a>
                </AntdCard>
            </motion.div>
        </AnimatePresence>
    );
};

export default Card;
